<template>
    <v-form class="custom-form" @submit.prevent.stop="login">
        <v-card elevation="2">
            <v-card-title class="fw-700 d-flex flex-row justify-space-between align-center blue-gradient-4">
                <span>Вход</span>
            </v-card-title>
            <!-- Body -->
            <v-card-text>
                <v-text-field
                    v-model="$v.form.login.$model"
                    :error-messages="getErrors('form.login')"
                    name="login" 
                    type="text" 
                    placeholder="Логин"
                    color="#2D9CDB"
                    flat
                    autofocus
                ></v-text-field>

                <v-text-field 
                    v-model="$v.form.password.$model"
                    :error-messages="[
                        ...getErrors('form.password'),
                        ...(!_.isNil(summaryError) ? [summaryError] : [])
                    ]"
                    name="password" 
                    :type="passwordView" 
                    placeholder="Пароль" 
                    color="#2D9CDB"
                    flat
                    append-icon="mdi-eye"
                    @click:append="togglePasswordView"
                ></v-text-field>
            </v-card-text>

            <v-card-text v-if="serverErrors">
                <div class="server-error mb-0">
                    <span class="ml-3">{{ serverErrors }}</span>
                    <img class="mr-2" src="@/assets/img/icon-error.svg">
                </div>
           </v-card-text>
            <!-- Footer -->
            <v-card-actions class="d-flex justify-center">
                <v-btn 
                    color="white" 
                    :elevation="0"
                    type="submit"
                    class="btn__blue"
                    @click.prevent.stop="login"
                >
                    Войти
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import { vuelidateErrorMixin } from '@/helpers/VuelidateHelpers'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/FormMixin'

export default {
    name: 'FakeLoginForm',
    mixins: [ validationMixin, vuelidateErrorMixin, FormMixin ],
    props: {
        keyLogin: { type: String, required: true },
        keyPassword: { type: String, required: true },
        autologinByQuery: { type: Boolean, required: false }
    },
    data () {
        return {
            form: {
                login: '',
                password: ''
            },
            passwordView: 'password'
        };
    },
    validations: {
        form: {
            login: { required },
            password: { required },
        }
    },
    mounted () {
        if (this.autologinByQuery && this.$route.query?.login && this.$route.query?.password) {
            this.form.login = this.$route.query.login
            this.form.password = this.$route.query.password
            this.login()
        }
    },
    methods: {
        togglePasswordView() {
            this.passwordView = this.passwordView === 'password' ? 'text' : 'password';
        },
        async login() {
            this.serverErrors = null;

            if (this.validate()) {

                const success = this.form.login === this.keyLogin && this.form.password === this.keyPassword;

                if (success)
                    this.$emit('success', 1);
                else
                    this.serverErrors = 'Неверный логин или пароль';
            }
        }
    }
}
</script>